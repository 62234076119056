import React from 'react';
import { Engine, Scene } from 'react-babylonjs';
import { Vector3, FreeCamera, HemisphericLight } from '@babylonjs/core';

const BabylonScene = () => {
  return (
    <Engine antialias adaptToDeviceRatio canvasId="babylonJSCanvas">
      <Scene>
        <FreeCamera name="camera" position={new Vector3(0, 5, -10)} setTarget={Vector3.Zero()} />
        <HemisphericLight name="light" intensity={0.7} direction={Vector3.Up()} />
      </Scene>
    </Engine>
  );
};

export default BabylonScene;
