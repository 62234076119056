import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import UserPool from "./UserPool";
import { useNavigate, Link } from "react-router-dom";
import './Login.css';
import logoImage from './ArtOfWarOnline.png'; 

const Login = () => {

    
    const[email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [resultMessage, setResultMessage] = useState("");
    const { authenticate, getSession, logout } = useContext(AccountContext);
    const [status, setStatus] = useState(false);
    const navigate=useNavigate();

    useEffect(() => {
      getSession()
          .then(session => {
              //console.log("Session: ", session);
              //console.log("Token: ", session.idToken.jwtToken);
              setStatus(true);
          })
          .catch(err => {
              //console.error("not logged in!", err);
              setStatus(false);
          });
    }, []);
    
    const onSubmit = (event) => {
        event.preventDefault();

        if (event.nativeEvent.submitter.textContent === "SignUp") {
          UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                console.error(err);
                setResultMessage("Error creating account");
            } else {
              console.log(data);
              setResultMessage("Account created!  Please check your email for verification link.");
            }
        });
        } else if (event.nativeEvent.submitter.textContent === "Login") {
          authenticate(email, password)
            .then(data => {
                sessionStorage.setItem('idToken',data.idToken.jwtToken);
                console.log("Logged in!", data);
                //window.location.reload();
                window.location.href = '/players';
            })
            .catch(err => {
                console.error("Failed to login", err);
                setResultMessage("Failed to login");
            });
        }
    };

    const handleLogout = () => {
      logout();
      window.location.reload();
    };
    
    return (
        <div className="login-container">
          <img src={logoImage} alt="Logo" className="logo" />

          {status ? 
          <p>Welcome! Use side bar menu to begin.  All warfare is based on deception - Sun Tzu.</p> :
          <form onSubmit={onSubmit}>
            <div style={{ marginBottom: '20px' }}>
              <input
                placeholder="Enter your email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              ></input>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <input
                placeholder="password (min 6 chars)"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              ></input>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="green-button" type="submit">
                Login
              </button>
              <button className="blue-button" type="submit">
                SignUp
              </button>
            </div>
            <div style={{ marginTop: '20px' }}>
              <p>{resultMessage}</p>
            </div>
          </form>
          }
        </div>
      );  
};

export default Login;