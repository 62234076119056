import React, { useState } from "react";
import UserPool from "./UserPool";
import './Login.css';
import logoImage from './ArtOfWarOnline.png'; 

const Signup = () => {

    const[email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = (event) => {
        event.preventDefault();

        UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                console.error(err);
            }
            console.log(data);
        });
    };

    return(
        <div className="login-container">
            <img src={logoImage} alt="Logo" className="logo" />
            <form onSubmit={onSubmit}>
                <div style={{ marginBottom: '20px' }}>
                    <input
                    placeholder="Enter your email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    ></input>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <input
                        placeholder="Enter your password"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    ></input>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button className="green-button" type="submit">
                    SignUp
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Signup;