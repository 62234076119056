import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../Account";
import Login from "../Login";

const ImageGallery = ({ handleLogoutClick }) => {
  const images = [
    {
      id: 1,
      src: 'image1.jpg',
      route: '/Players',
      alt: 'Player Select',
    },
    {
      id: 2,
      src: 'image2.jpg',
      route: '/Prizes',
      alt: 'Claim Prize',
    },
    {
      id: 3,
      onClick: handleLogoutClick,
      alt: 'Logout',
    },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
      {images.map((image) => (
        <div key={image.id} onClick={image.onClick}>
          {image.route ? (
            <a href={image.route} rel="noopener noreferrer">
              <img
                src={image.src}
                alt={image.alt}
                style={{ width: '', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
              />
            </a>
          ) : (
            <img
              src={image.src}
              alt={image.alt}
              style={{ width: '', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const Profile = () => {
  const [status, setStatus] = useState(false);

  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then(session => {
        console.log("logged in!");
        setStatus(true);
      })
      .catch(err => {
        console.error("not logged in!", err);
        setStatus(false);
      });
  }, []);

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      {status ? <ImageGallery handleLogoutClick={handleLogoutClick} /> : <Login />}
    </div>
  );
};

export default Profile;
