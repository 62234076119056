import React, { useState, useEffect, useContext } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "../App.css";
import { IconContext } from "react-icons";
import * as CgIcons from "react-icons/cg";
import { AccountContext } from "../Account";


function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const { getSession, logout } = useContext(AccountContext);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getSession()
        .then(session => {
            //console.log("Session: ", session);
            //console.log("Token: ", session.idToken.jwtToken);
            setStatus(true);
        })
        .catch(err => {
            //console.error("not logged in!", err);
            setStatus(false);
        });
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            {status ?  
            <li key={SidebarData.length} className="nav-text">
            <Link to={"/loggedOff"}>
            <CgIcons.CgLogOff />
              <span>{"Log Off"}</span>
            </Link>
            </li>:<li></li>}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
