import React, { useState } from "react";


function Prizes() {
  return (
    <div className="products">
      <h1>Claim a Prize</h1>
    </div>
  );
}

export default Prizes;
