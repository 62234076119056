import React, { useEffect, useRef, useState } from 'react';
import { Engine, ArcRotateCamera, HemisphericLight, Scene, Vector3, SceneLoader, ActionManager, ExecuteCodeAction, Mesh, Texture, StandardMaterial, Color3  } from '@babylonjs/core';
import { Control, AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Players.css'; // Import CSS file for styling

const SceneComponent = (props) => {
    const reactCanvas = useRef(null);
    const [citiesData, setCitiesData] = useState(null);

    const {
        canvasId,
        antialias,
        engineOptions,
        adaptToDeviceRatio,
        sceneOptions,
        onRender,
        onSceneReady,
        ...rest
    } = props;


    useEffect(() => {
        if (!reactCanvas.current) return;

        const fetchData = async () => {
            try {
				const headers = { 'Authorization': 'Bearer ' + sessionStorage.getItem('idToken') };
                const response = await axios.get('https://85y76yqgqb.execute-api.us-west-1.amazonaws.com/dev/cities/', { headers });
                setCitiesData(JSON.parse(response.data.body));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (!reactCanvas.current) return;

        const engine = new Engine(
            reactCanvas.current,
            antialias,
            engineOptions,
            adaptToDeviceRatio
        );

        const scene = new Scene(engine, sceneOptions);

        if (scene.isReady()) {
            onSceneReady(scene, citiesData);
        } else {
            scene.onReadyObservable.addOnce(() => onSceneReady(scene, citiesData));
        }

        engine.runRenderLoop(() => {
            onRender(scene);
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        window.addEventListener('resize', resize);

        return () => {
            scene.getEngine().dispose();
            window.removeEventListener('resize', resize);
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, citiesData]);

    return (
    <canvas id={canvasId} ref={reactCanvas} {...rest} />
    );
};

function App() {
    // Navigate to the desired route
    const navigate = useNavigate();

    const onSceneReady = (scene, citiesData) => {
        var camera = new ArcRotateCamera("Camera", -Math.PI / 2, 1.1, 10, new Vector3(0, 0, 0), scene);
        camera.setTarget(Vector3.Zero());

        camera.lowerBetaLimit = 0.1;
        camera.upperBetaLimit = (Math.PI / 2) * 0.9;
    
        const canvas = scene.getEngine().getRenderingCanvas();
        camera.attachControl(canvas, true);
    
        var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;
    
        /*
        var ground = Mesh.CreateGroundFromHeightMap("ground", "assets/terrain/snow_height.bmp", 10, 10, 10, 0, 1, scene, false);
        var groundMaterial = new StandardMaterial("ground", scene);
        groundMaterial.diffuseTexture = new Texture("assets/terrain/snow.png", scene);
        groundMaterial.specularColor = Color3.Black();
        ground.material = groundMaterial;
        */

        var ground = Mesh.CreateGround("ground", 10, 10, 1, scene);
        var groundMaterial = new StandardMaterial("ground", scene);
        groundMaterial.diffuseTexture = new Texture("assets/terrain/snow.png", scene);
        groundMaterial.specularColor = Color3.Black();
        ground.material = groundMaterial;
    
        // Create the AdvancedDynamicTexture outside the loop
        var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    
        if (citiesData != null) {
            citiesData.forEach((cityData, index) => {
                //console.log(cityData.city);
    
                SceneLoader.ImportMesh("", "assets/", "tower.babylon", scene, function (newMeshes) {
                    var mesh = newMeshes[0];
                    mesh.scaling = new Vector3(.05, .05, .05);
    
                    //var elevation = ground.getHeightAtCoordinates(cityData.longtitude, cityData.latitude);
    
                    mesh.position = new Vector3(cityData.longtitude, 0, cityData.latitude);
                    mesh.rotation.y = cityData.rotation;
    
                    var label = new TextBlock();
                    label.text = cityData.city;
                    label.color = "Yellow";
    
                    // Add the label control to the common AdvancedDynamicTexture instance
                    advancedTexture.addControl(label);
    
                    // Link the label with the mesh
                    label.linkWithMesh(mesh);
                    label.linkOffsetY = -100;

                    mesh.actionManager = new ActionManager(scene);
                    mesh.actionManager.registerAction(
                    new ExecuteCodeAction(
                        ActionManager.OnPickTrigger,
                        function () {
                            // ENTER CITY
                            navigate('/city', {
                                state: {
                                  city: cityData.city,
                                  cityId: cityData.id,
                                }
                              });
                        }
                    )
                    );
    
                    // Other info...
                });
            });
        }
    };
    

    const onRender = (scene) => {};

    return (
        <div className="App">
            <SceneComponent
                canvasId="babylon-canvas"
                antialias={true}
                onSceneReady={onSceneReady}
                onRender={onRender}
            />
        </div>
    );
};

export default App;
