import React, { useEffect, useRef, useState } from 'react';
import { Engine, ArcRotateCamera, HemisphericLight, Scene, Vector3, SceneLoader, ActionManager, ExecuteCodeAction, Mesh, Texture, StandardMaterial, Color3  } from '@babylonjs/core';
import { Control, AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';
import axios from 'axios';
import { useNavigate,  useLocation } from 'react-router-dom';
import './Players.css'; // Import CSS file for styling

const SceneComponent = (props) => {
    const reactCanvas = useRef(null);
    const [citiesData, setCitiesData] = useState(null);

    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');

    const {
        canvasId,
        antialias,
        engineOptions,
        adaptToDeviceRatio,
        sceneOptions,
        onRender,
        onSceneReady,
        ...rest
    } = props;

    const location = useLocation();

    useEffect(() => {
        if (!reactCanvas.current) return;

        const fetchData = async () => {
            try {
                console.log(location.state.city);
				const headers = { 'Authorization': 'Bearer ' + sessionStorage.getItem('idToken') };
                //console.log(headers);
                const response = await axios.get('https://85y76yqgqb.execute-api.us-west-1.amazonaws.com/dev/city/'+location.state.city, { headers });
                console.log(response.data.body);
                setCitiesData(JSON.parse(response.data.body));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        
    }, []);

    useEffect(() => {
        // Connect to AWS API Gateway WebSocket
        const ws = new WebSocket('wss://5xyirmonv2.execute-api.us-west-1.amazonaws.com/production?cityId='+location.state.cityId);
    
        ws.onopen = () => {
          console.log('Connected to WebSocket');
          
        };
    
        ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, message]);
          //setCitiesData(JSON.parse(response.data.body));
        };
    
        ws.onclose = () => {
          console.log('WebSocket disconnected');
        };
    
        setSocket(ws);
    
        return () => {
          // Cleanup function to close WebSocket connection when component unmounts
          ws.close();
        };
      }, []);

      const sendMessage = () => {
        if (inputMessage.trim() !== '') {
          const messageObject = { message: inputMessage };
          socket.send(JSON.stringify(messageObject));
          setInputMessage('');
        }
      };

    useEffect(() => {
        if (!reactCanvas.current) return;

        const engine = new Engine(
            reactCanvas.current,
            antialias,
            engineOptions,
            adaptToDeviceRatio
        );

        const scene = new Scene(engine, sceneOptions);

        if (scene.isReady()) {
            onSceneReady(scene, citiesData);
        } else {
            scene.onReadyObservable.addOnce(() => onSceneReady(scene, citiesData));
        }

        engine.runRenderLoop(() => {
            onRender(scene);
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        window.addEventListener('resize', resize);

        return () => {
            scene.getEngine().dispose();
            window.removeEventListener('resize', resize);
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, citiesData]);

    return (
    <canvas id={canvasId} ref={reactCanvas} {...rest} />
    );
};

function App() {
    // Navigate to the desired route
    const navigate = useNavigate();

    const onSceneReady = (scene, citiesData) => {
        var camera = new ArcRotateCamera("Camera", -Math.PI / 2, 1.1, 10, new Vector3(0, 0, 0), scene);
        camera.setTarget(Vector3.Zero());
        
        camera.lowerBetaLimit = 0.1;
        camera.upperBetaLimit = (Math.PI / 2) * 0.99;
        
        scene.clearColor = new Color3(0, 0, .2);
    
        const canvas = scene.getEngine().getRenderingCanvas();
        camera.attachControl(canvas, true);
    
        var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;
    
        var ground = Mesh.CreateGround("ground", 6, 6, 1, scene);
        var groundMaterial = new StandardMaterial("ground", scene);
        groundMaterial.diffuseTexture = new Texture("https://upload.wikimedia.org/wikipedia/commons/d/d5/Chess_Board.svg", scene);
        groundMaterial.specularColor = Color3.Black();
        ground.material = groundMaterial;
        
        // Create the AdvancedDynamicTexture outside the loop
        var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    
        if (citiesData != null) {

            console.log(citiesData[0]);
            var cityOccupiers = [];

            if (citiesData[0].center){
                cityOccupiers.push(["C", citiesData[0].center]);
            } else {
                cityOccupiers.push(["C", "throne", ""]);
            }
            
            if (citiesData[0].north){
                cityOccupiers.push(["N", citiesData[0].north]);
            }

            if (citiesData[0].northeast){
                cityOccupiers.push(["NE", citiesData[0].northeast]);
            }

            if (citiesData[0].east){
                cityOccupiers.push(["E", citiesData[0].east]);
            }

            if (citiesData[0].southeast){
                cityOccupiers.push(["SE", citiesData[0].southeast]);
            }

            if (citiesData[0].south){
                cityOccupiers.push(["S", citiesData[0].south]);
            }

            if (citiesData[0].southwest){
                cityOccupiers.push(["SW", citiesData[0].southwest]);
            }

            if (citiesData[0].west){
                cityOccupiers.push(["W", citiesData[0].west]);
            }

            if (citiesData[0].northwest){
                cityOccupiers.push(["NW", citiesData[0].northwest]);
            }

            
            cityOccupiers.forEach((cityOccupier, index) => {
                console.log(cityOccupier);
   
                SceneLoader.ImportMesh("", "assets/", cityOccupier[1]+".babylon", scene, function (newMeshes) {
                    var mesh = newMeshes[0];
                    mesh.scaling = new Vector3(.3, .3, .3);

                    switch(cityOccupier[0]){
                        case "C":
                            mesh.position = new Vector3(0, 0, 0);
                            mesh.rotation.y = 0;
                            break;
                        case "N":
                            mesh.position = new Vector3(0, 0, 0);
                            mesh.rotation.y = 0;
                            break;
                        default:
                    }

                    var label = new TextBlock();
                    label.text = cityOccupier[2];
                    label.color = "Yellow";
    
                    // Add the label control to the common AdvancedDynamicTexture instance
                    advancedTexture.addControl(label);
    
                    // Link the label with the mesh
                    label.linkWithMesh(mesh);
                    label.linkOffsetY = -100;

                    mesh.actionManager = new ActionManager(scene);
                    mesh.actionManager.registerAction(
                    new ExecuteCodeAction(
                        ActionManager.OnPickTrigger,
                        function () {
                            // ENTER CITY
                            alert(cityOccupier[0]);
                        }
                    )
                    );
    
                    // Other info...
                });
            });
        }
    };
    

    const onRender = (scene) => {};

    return (
        <div className="App">
            <SceneComponent
                canvasId="babylon-canvas"
                antialias={true}
                onSceneReady={onSceneReady}
                onRender={onRender}
            />
        </div>
    );
};

export default App;
