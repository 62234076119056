import { Link } from "react-router-dom";
import { AccountContext } from "./Account";
import React, { useContext } from "react";
import logoImage from './ArtOfWarOnline.png'; 

const LoggedOff = () => {

    const { logout } = useContext(AccountContext);

    logout();

    return ( 
        <div className="login-container">
          <img src={logoImage} alt="Logo" className="logo" />
            <div style={{ marginTop: '20px' }}>
              <p>You are now logged off.  All warfare is based on deception - Sun Tzu  <Link to={'/login'} className="btn btn-primary">Log back in</Link></p>
            </div>
        </div>
     );
}
 
export default LoggedOff;