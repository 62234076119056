import React, { useEffect, useRef, useState } from 'react';
import { Engine, ArcRotateCamera, HemisphericLight, Scene, Vector3, SceneLoader, ActionManager, ExecuteCodeAction } from '@babylonjs/core';
import { Control, AdvancedDynamicTexture, TextBlock, InputText, Button  } from '@babylonjs/gui';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Players.css'; // Import CSS file for styling

const SceneComponent = (props) => {
    const reactCanvas = useRef(null);
    const [meshesData, setMeshesData] = useState(null);

    const {
        canvasId,
        antialias,
        engineOptions,
        adaptToDeviceRatio,
        sceneOptions,
        onRender,
        onSceneReady,
        ...rest
    } = props;

    useEffect(() => {
        if (!reactCanvas.current) return;

        const fetchData = async () => {
            try {
				const headers = { 'Authorization': 'Bearer ' + sessionStorage.getItem('idToken') };
                const response = await axios.get('https://85y76yqgqb.execute-api.us-west-1.amazonaws.com/dev/players/', { headers });
                setMeshesData(JSON.parse(response.data.body));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!reactCanvas.current) return;

        const engine = new Engine(
            reactCanvas.current,
            antialias,
            engineOptions,
            adaptToDeviceRatio
        );

        const scene = new Scene(engine, sceneOptions);

        if (scene.isReady()) {
            onSceneReady(scene, meshesData);
        } else {
            scene.onReadyObservable.addOnce(() => onSceneReady(scene, meshesData));
        }

        engine.runRenderLoop(() => {
            onRender(scene);
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        window.addEventListener('resize', resize);

        return () => {
            scene.getEngine().dispose();
            window.removeEventListener('resize', resize);
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, meshesData]);

    return (
    <canvas id={canvasId} ref={reactCanvas} {...rest} />
    );
};

function App() {
    // Navigate to the desired route
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/createplayer');
    };

    const onSceneReady = (scene, meshesData) => {
        var camera = new ArcRotateCamera("Camera", -Math.PI / 2, 1.1, 10, new Vector3(0, 0, 0), scene);

        camera.setTarget(Vector3.Zero());

        camera.lowerBetaLimit = 0.1;
        camera.upperBetaLimit = (Math.PI / 2) * 0.99;

        const canvas = scene.getEngine().getRenderingCanvas();
        camera.attachControl(canvas, true);

        var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        // UI
        var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

        if (meshesData != null) {
            if (meshesData.length < 7) {
            var button = Button.CreateSimpleButton("but1", "New Player");
            button.paddingTop = "10px";
            button.width = "200px";
            button.height = "50px";
            button.color = "white";
            button.top = "-300"
            button.background = "green";
            button.onPointerDownObservable.add(handleButtonClick);
            advancedTexture.addControl(button);
            }

            // Positioning meshes in a circular arrangement
        const radius = (meshesData.length - 1) / 2; // Adjust the radius as needed
        const angleIncrement = (2 * Math.PI) / meshesData.length; // Divide 2π by the number of meshes

        meshesData.forEach((meshData, index) => {

            console.log(meshData.player);
            const angle = index * angleIncrement;
            const x = radius * Math.cos(angle);
            const z = radius * Math.sin(angle);
            let y = 0;

            SceneLoader.ImportMesh("", "assets/", meshData.type + ".babylon", scene, function (newMeshes, particleSystems, skeletons) {
                var skeleton = skeletons[0];
                var mesh = newMeshes[0];

                switch(meshData.type){
                    case "knight":
                        y = 2.65;
                        break;
                    case "centurion":
                        y = 1.25;
                        break;
                    default:
                        y = 1.5;
                }
                    
                mesh.position = new Vector3(x, y, z); // Set mesh position

                // Calculate angle for rotation
                var rotationAngle = Math.atan2(x, z) + Math.PI;

                // Rotate mesh
                mesh.rotation.y = rotationAngle;

                // Create GUI text for label
                var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
                var label = new TextBlock();
                label.text = meshData.player + "-" + meshData.title; // Use label from data
                label.color = "Yellow";
                label.isVisible = false; // Initially hide label
                advancedTexture.addControl(label);

                // Event handlers for hover
                mesh.actionManager = new ActionManager(scene);
                mesh.actionManager.registerAction(
                    new ExecuteCodeAction(
                        ActionManager.OnPointerOverTrigger,
                        function () {
                            label.isVisible = true; // Show label on hover
                            label.linkWithMesh(mesh);
                            label.linkOffsetY = -100; // Adjust as needed
                            //scene.beginAnimation(skeleton, 1, 338, true);
                        }
                    )
                );
                mesh.actionManager.registerAction(
                    new ExecuteCodeAction(
                        ActionManager.OnPointerOutTrigger,
                        function () {
                            label.isVisible = false; // Hide label on hover out
                        }
                    )
                );
            });
        });
        }
    };

    const onRender = (scene) => {};

    return (
        <div className="App">
            <SceneComponent
                canvasId="babylon-canvas"
                antialias={true}
                onSceneReady={onSceneReady}
                onRender={onRender}
            />
        </div>
    );
};

export default App;
