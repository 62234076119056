import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./routes/Home";
import Reports from "./routes/Reports";
import Profile from "./routes/Profile";
import Prizes from "./routes/Prizes";
import Players from "./routes/Players";
import CreatePlayer from "./routes/CreatePlayer";
import Map from "./routes/Map";
import City from "./routes/City";
import Login from "./Login";
import Signup from "./Signup";
import LoggedOff from "./LoggedOff";
import UnderConstruction from "./UnderConstruction";
import Navbar from "./components/Navbar";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import { Account } from "./Account";
import BackgroundMusicPlayer from './BackgroundMusicPlayer';

const AppLayout = () => (
  <>
    <BackgroundMusicPlayer url="./ArtOfWarTheme.mp3" />
    <Navbar />
    <Outlet />
  </>
);

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route element={<AppLayout />}>
//       <Route path="/" element={<Home />} />
//       <Route path="/products" element={<Products />} />
//       <Route path="/reports" element={<Reports />} />
//     </Route>
//   )
// );

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "reports/*",
        element: <PrivateRoute path="reports" element={<Reports />} />,
      },
      {
        path: "profile/*",
        element: <PrivateRoute path="profile" element={<Profile />} />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "prizes/*",
        element: <PrivateRoute path="profile" element={<Prizes />} />,
      },
      {
        path: "players/*",
        element: <PrivateRoute path="profile" element={<Players />} />,
      },
      {
        path: "createplayer/*",
        element: <PrivateRoute path="profile" element={<CreatePlayer />} />,
      },
      {
        path: "map/*",
        element: <PrivateRoute path="profile" element={<Map />} />,
      },
      {
        path: "city/*",
        element: <PrivateRoute path="profile" element={<City />} />,
      },
      {
        path: "loggedOff",
        element: <LoggedOff />,
      },
      {
        path: "underconstruction",
        element: <UnderConstruction />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <Account>
    <RouterProvider router={router} />
  </Account>
);
