import {
  Engine,
  ArcRotateCamera,
  HemisphericLight,
  MeshBuilder,
  Scene,
  Vector3,
  SceneLoader,
} from '@babylonjs/core';

import { Control, AdvancedDynamicTexture, InputText, Button } from '@babylonjs/gui';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useRef, useState } from 'react';
import './Players.css'; // Import CSS file for styling

const SceneComponent = (props) => {
  const reactCanvas = useRef(null);

  const {
    canvasId,
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props;

  useEffect(() => {
    if (!reactCanvas.current) return;

    const engine = new Engine(
      reactCanvas.current,
      antialias,
      engineOptions,
      adaptToDeviceRatio
    );

    const scene = new Scene(engine, sceneOptions);

    if (scene.isReady()) {
      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce(onSceneReady);
    }

    engine.runRenderLoop(() => {
      onRender(scene);
      scene.render();
    });

    const resize = () => {
      scene.getEngine().resize();
    };

    if (window) {
      window.addEventListener('resize', resize);
    }

    return () => {
      scene.getEngine().dispose();
      if (window) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
  ]);

  return <canvas id={canvasId} ref={reactCanvas} {...rest} />;
};


function App() {
  // Navigate to the desired route
  const navigate = useNavigate();

  const handleButtonClick = () => {
      navigate('/createplayer');
  };

const onSceneReady = (scene) => {
  var camera = new ArcRotateCamera("Camera", -Math.PI/2, 1.1, 5, new Vector3(0, 0, 0), scene);

  camera.setTarget(Vector3.Zero());

  camera.lowerBetaLimit = 0.1;
  camera.upperBetaLimit = (Math.PI / 2) * 0.99;

  const canvas = scene.getEngine().getRenderingCanvas();
  camera.attachControl(canvas, true);

  var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
  light.intensity = 0.7;

  //box = MeshBuilder.CreateBox('box', { size: 2 }, scene);
  //box.position.y = 1;

  //MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);

  const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('UI');

  let playerName = '';
  let playerTitle = '';
  let playerType = '';
  let playerTypes = ['viking', 'centurion', 'knight']; // Add different player types here
  let currentTypeIndex = 0;
  let currentMeshes = null; // Store the current loaded meshes

   // Create input text
   const playerInput = new InputText();
   playerInput.width = '200px';
   playerInput.height = '40px';
   playerInput.color = 'white';
   playerInput.background = 'black';
   playerInput.placeholderText = 'Enter player name';
   playerInput.top = -300;
   playerInput.onTextChangedObservable.add((newText) => {
    playerName = newText;
   });
   advancedTexture.addControl(playerInput);

  // Create input text
  const titleInput = new InputText();
  titleInput.width = '200px';
  titleInput.height = '40px';
  titleInput.color = 'white';
  titleInput.background = 'black';
  titleInput.placeholderText = 'Enter title (optional)';
  titleInput.top = -250;
  titleInput.onTextChangedObservable.add((newText) => {
    playerTitle = newText;
  });
  advancedTexture.addControl(titleInput);

  const lButton = Button.CreateSimpleButton('left-button', '<');
  lButton.width = '40px';
  lButton.height = '40px';
  lButton.color = 'white';
  lButton.background = 'green';
  lButton.left = -200;
  lButton.onPointerUpObservable.add(() => {
    currentTypeIndex = (currentTypeIndex + 1) % playerTypes.length;

    if (currentMeshes) {
      currentMeshes.forEach(mesh => {
        mesh.dispose();
      });
    }

    SceneLoader.ImportMesh("", "assets/", playerTypes[currentTypeIndex] + ".babylon", scene, function (newMeshes, particleSystems, skeletons) {
      currentMeshes = newMeshes;
      var skeleton = skeletons[0];
      newMeshes[0].position = new Vector3(0, 0, 0); // Replace x, y, z with desired coordinates
      scene.beginAnimation(skeleton, 1, 338, true);
    });

  });
      
  advancedTexture.addControl(lButton);

  const rButton = Button.CreateSimpleButton('right-button', '>');
  rButton.width = '40px';
  rButton.height = '40px';
  rButton.color = 'white';
  rButton.background = 'green';
  rButton.left = 200;
  rButton.onPointerUpObservable.add(() => {
    currentTypeIndex = (currentTypeIndex - 1 + playerTypes.length) % playerTypes.length;

    if (currentMeshes) {
      currentMeshes.forEach(mesh => {
        mesh.dispose();
      });
    }

    SceneLoader.ImportMesh("", "assets/", playerTypes[currentTypeIndex] + ".babylon", scene, function (newMeshes, particleSystems, skeletons) {
      currentMeshes = newMeshes;
      var skeleton = skeletons[0];
      newMeshes[0].position = new Vector3(0, 0, 0); // Replace x, y, z with desired coordinates
      scene.beginAnimation(skeleton, 1, 338, true);
    });
  });
      
  advancedTexture.addControl(rButton);

  // Create button
  const crButton = Button.CreateSimpleButton('submit-button', 'Create');
  crButton.width = '100px';
  crButton.height = '40px';
  crButton.color = 'white';
  crButton.background = 'green';
  crButton.top = 150;
  crButton.onPointerUpObservable.add(() => {
    const headers = { 'Authorization': 'Bearer ' + sessionStorage.getItem('idToken') };
    playerType = playerTypes[currentTypeIndex];

    const data = {player: playerName.text, title: playerTitle.text, type: playerType};

    console.log(data);
    const body = data;
    //const body = JSON.stringify(data);
    
      axios.post('https://85y76yqgqb.execute-api.us-west-1.amazonaws.com/dev/player/', body, { headers }).then(res=>{
        navigate('/players');
        }).catch(err=>{
          console.log(err);
        })
    });
      
  advancedTexture.addControl(crButton);

  SceneLoader.ImportMesh("", "assets/", playerTypes[currentTypeIndex] + ".babylon", scene, function (newMeshes, particleSystems, skeletons) {
    currentMeshes = newMeshes;
    var skeleton = skeletons[0];
    newMeshes[0].position = new Vector3(0, 0, 0); // Replace x, y, z with desired coordinates
    scene.beginAnimation(skeleton, 1, 338, true);
  });
};

const onRender = (scene) => {};

return (
  <div className="App">
      <SceneComponent
          canvasId="babylon-canvas"
          antialias={true}
          onSceneReady={onSceneReady}
          onRender={onRender}
      />
  </div>
);
};

export default App;
