// PrivateRoute.js
import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const [authenticated, setAuthenticated] = useState('loading');
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then(session => {
        console.log("Logged in!");
        setAuthenticated(true);
      })
      .catch(err => {
        console.error("Not logged in!", err);
        setAuthenticated(false);
      });
  }, [getSession]);

  if (authenticated === 'loading') {
    // Loading state, you might want to render a loading spinner or some indicator
    return <div>Loading...</div>;
  }

  return authenticated ? (
    element
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
