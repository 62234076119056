// BackgroundMusicPlayer.js
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const BackgroundMusicPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(true);

  // Ensure music plays continuously
  useEffect(() => {
    setPlaying(true);
  }, []);

  return (
    <ReactPlayer
      url={url}
      playing={playing}
      loop={true}
      volume={0.5} // Adjust volume as needed
      width="0"
      height="0"
    />
  );
};

export default BackgroundMusicPlayer;
