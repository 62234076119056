import logoImage from './ArtOfWarOnline.png'; 

const UnderConstruction = () => {

    return ( 
        <div className="login-container">
          <img src={logoImage} alt="Logo" className="logo" />
            <div style={{ marginTop: '20px' }}>
              <p>Under Construction...Coming Soon!</p>
            </div>
        </div>
     );
}
 
export default UnderConstruction;