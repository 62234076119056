import React from "react";
import * as GiIcons from "react-icons/gi";

export const SidebarData = [
  {
    title: "Players",
    path: "/players",
    icon: <GiIcons.GiSwordman />,
    cName: "nav-text",
  },
  {
    title: "World Map",
    path: "/map",
    icon: <GiIcons.GiTreasureMap />,
    cName: "nav-text",
  },
  {
    title: "Diplomacy",
    path: "/underconstruction",
    icon: <GiIcons.GiDove />,
    cName: "nav-text",
  },
  {
    title: "Wars",
    path: "/underconstruction",
    icon: <GiIcons.GiTrumpetFlag />,
    cName: "nav-text",
  },
  {
    title: "Battles",
    path: "/underconstruction",
    icon: <GiIcons.GiBattleGear />,
    cName: "nav-text",
  },
  {
    title: "Alliances",
    path: "/underconstruction",
    icon: <GiIcons.GiRallyTheTroops />,
    cName: "nav-text",
  },
  {
    title: "Messages",
    path: "/underconstruction",
    icon: <GiIcons.GiEnvelope />,
    cName: "nav-text",
  },
  {
    title: "Prizes",
    path: "/underconstruction",
    icon: <GiIcons.GiQueenCrown />,
    cName: "nav-text",
  },
];
